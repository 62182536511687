/**
 * The formatters-custom file is a place to define custom formatters or to
 * override any of the baked-in formatters like phone number formatting.
 *
 * e.g. You may write something like below
 * export function address(profile) {
 *   return `111 Yext Street`;
 * }
 */

/**
 * Given a single instance of the KnowledgeGraph is populated from the Production Author AEM Instance
 * all Landing Page Url references are pointed at production links (i.e. www.cir2.com).
 * 
 * Interrogate the current hostname to determine if the search experience is hosted in a non-production
 * AEM instance and rewrite AEM link urls to reflect the appropriate AEM instance. 
 *
 * @param {string} url 
 * @returns {string|null}
 */
export function formatUrlForAEMInstance(url) {
  switch (window.location.hostname) {
    case 'test.cir2.com':
    case 'intg.cir2.com':
    case 'uat.cir2.com':
      return url.replace('www.cir2.com', window.location.hostname);
    default:
      return url;
  }
}